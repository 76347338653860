import React from 'react'
import "./Loader.css";

const Loader = () => {
  return (
    <>
    <div className="w100">
      <div className="logoloader">
        <img
          className="logoloaderimg"
          src="/images/lattest/nedl_rgb.png"
        />
      <div className="loading">
        <span className="loadingdot"></span>
        <span className="loadingdot"></span>
        <span className="loadingdot"></span>
      </div>
      </div>
    </div>
  </>
  )
}

export default Loader
