import React, { useEffect } from 'react'
import axios from "axios";
import { useNavigate } from 'react-router-dom';



const NoSubscription = () => {
    // const useremail = sessionStorage.getItem("useremail");
    // const navigate = useNavigate();

    // useEffect(()=>{
    //   const handleClick = (event) =>{
    //     handleLogout(useremail);
    //   }
    //   document.addEventListener('click', handleClick);
    //   return()=>{
    //     document.removeEventListener('click', handleClick);
    //   };
    // },[])
    // const handleLogout = async (email) => {
    //     const newItem = {
    //       "email": email,
    //     };
    //     await axios
    //         .post(
    //           `${process.env.REACT_APP_USER_LOGOUT}`,newItem,
    //         )
    //         .then((res) => {
    //           if(res.status === 200){
    //             console.log("logged out successfully");
    //             sessionStorage.setItem('logged',false);
    //             navigate('/')  
    //           }
    //         }
    //         )
    //         .catch((err) =>{
    //             let error = err.response.data.error;
    //             if(error.message === "The User is already logged out"){
    //                 navigate("/");
    //             }
    //             console.log(err);
    //         })
    //     }


    return(
        <div className='w100 h100'>
        <div style={{backgroundImage:"url('/images/lattest/bgimage.svg')",width:"100%",height:"100%",display:"flex",justifyContent:"center",alignItems:"center"}}>
        <div style={{fontFamily:"Calibri",width:"50%",padding:"10px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
          <img src="/images/lattest/mainimg.png" alt="Image" />
         <h1 style={{fontSize:"42px",marginBottom:"0px",textAlign:"center"}}>Please add subscription to access this page.</h1>
        <p style={{width:"100%",marginTop:"5px",wordWrap:"break-word",textAlign:"center",fontSize:"18px",fontWeight:"400",color:"darkgray"}}>
           We're sorry, the page you requested could not be loaded <br />Please check your subscription or add a new subscription.
            </p>
        </div>
        </div>
        </div>
            );
}

export default NoSubscription
