
import React, { useState, useEffect } from "react";
import "./loginold.css";
import FormMiddleware from "../middlewares/FormMiddleware";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Link ,useParams} from "react-router-dom";
import { sendUsername } from "../middlewares/User";
import { PhotoService } from '../service/PhotoService';
import { Galleria } from 'primereact/galleria';
import { Password } from 'primereact/password';
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import ToastComponent from "../components/AAANEWCOMPONENT/toast";
import http from "../config/axiosConfig";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";


const VerifyPassword = () => {
 

  const[usererror,setUserError]=useState(false);
  const[usererrormsg,setUserErrorMessage]=useState("");
  const[loginerror,setLoginError]=useState(false);
    const[newUser,setNewUser]=useState(false);
    const[loginerrormsg,setLoginErrorMessage]=useState("");
    const[pwderrormsg,setPwdErrorMsg]=useState(false);
    const[errortoast,setErrorToast] = useState(false);
    const[images,setImages] = useState([]);
    const[username,setUsername]=useState("");
    const[usernameplaceholder,setUsernamePlaceholder]=useState("");
    const[password,setPassword]=useState("");
    const[resetkey,setResetKey]=useState("");
    const[loginotp,setLoginOtp]=useState("");
    const[newpassword,setNewPassword]=useState("");
    const[confirmpassword,setConfirmPassword]=useState("");
    const[mailerrormsg,setMailErrorMsg]=useState(false);
    const[reseterrormsg,setResetErrorMsg]=useState(false);
    const[pwdMatch,setPwdMatchError]=useState(false);
  const galleriaService = new PhotoService();
  const navigate = useNavigate();
  const[resettoast,setResetToast] = useState(false);
  const [displayBasic, setDisplayBasic] = useState(false);
 const [showlogoutMessage, setShowLoggedOutMsg] = useState(false);
 const[submitUser,setSubmitUser] = useState(false);



   const responsiveOptions = [
    {
      breakpoint: '1024px',
      numVisible: 5
    },
    {
      breakpoint: '768px',
      numVisible: 3
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];
  const itemTemplate = (item) => {
    return <img className="gi" src={item.image} alt={item.name} />;
  }
  useEffect(() => {
    galleriaService.getImages().then(data => setImages(data));
}, [])
  const caption = (item) => {
    return (
      <React.Fragment>
        <h4 className="p-mb-2">{item.title}</h4>
        <p>{item.alt}</p>
      </React.Fragment>
    );
  }

 
  const validatePassword = async (e) => {
    //validate password and username for Login
    e.preventDefault();
    setSubmitUser(true);
    setShowLoggedOutMsg(false);
    handleLogin();
  };
  // const location = useLocation();
  // const { email } = location.state;
  const handleLogin = async () =>{
   const useremail = sessionStorage.getItem('useremail');
  const newItem = {
    "email": useremail,
    "password":password,
    "otp": loginotp*1

  };
    if (password !== '') {
      const result =  await axios.post(`${process.env.REACT_APP_LOGIN}`,newItem
      ).then((data) => {
        console.log(data,'login successful')
        setLoginError(false);
        setSubmitUser(false);
        return data

      })
      .catch((err) =>{
        let error = err.response.data.error;
        if(error.message === "User is already Signed In to another System"){
         setDisplayBasic(true);
        setSubmitUser(false);
        }else{
          setLoginError(true);
          if(error.message === "Wrong username / password"){
            setLoginErrorMessage("Wrong password");
          }else{
          setLoginErrorMessage(error.message);}
          setSubmitUser(false);
        }
      }
        );

      

      if (result?.status === 200) {
        sessionStorage.setItem(
          "session_data",
          JSON.stringify(result)
        );
        setSubmitUser(false);
        const userDetails = JSON.parse(sessionStorage.getItem("session_data"));
        const userToken = userDetails.data.token;
        const users =  await axios.get(`${process.env.REACT_APP_USERME}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
        ).then((data) => {
        return data
    
        })
        sessionStorage.setItem(
          "userdata",
          JSON.stringify(users)
        );
    sessionStorage.setItem('logged',true)
    http.defaults.headers.common["access_token"] =
    result.headers.access_token;
  http.defaults.headers.common["authorizedtoken"] =
    result.headers.authorizedtoken;
   setPassword("");
   redirect();
    }
  }else{
      setPwdErrorMsg(true);
    }
 }
 const redirect = () =>{
  if(sessionStorage?.getItem("logged") !== true){
    window.location.replace('/market')

  }
 }
const handleLogout = async () => {
  const useremail = sessionStorage.getItem('useremail');
  const newItem = {
    "email": useremail,
  };
  await axios
      .post(
        `${process.env.REACT_APP_USER_LOGOUT}`,newItem, 
      )
      .then((res) => {
        if(res.status === 200){
           navigate('/')
           setSubmitUser(false);
           setDisplayBasic(false);
           setShowLoggedOutMsg(true);
           setTimeout(function () {
            setShowLoggedOutMsg(false);
          }, 5000);
          handleLogin();
        }
    })
      .catch((err) => {console.log(err);              
          setSubmitUser(false);
      });
  }
 
 
 const newtabDialogue = () => {
  return <>
    <Dialog header="Multiple login detected" visible={displayBasic} draggable={false} style={{ marginLeft: '70px', width: '30vw' }} onHide={() => setDisplayBasic(false)}>
     <>
     <div className="alignnotes">
      <p className="addchangestyle">Sign in on this device? You'll be logged out from the other device</p>
      </div>
     </>
      <div className="dealfooter">
      <Button  className="dealbtn" onClick={() => handleLogout()}>Yes</Button>
      <Button onClick={()=> setDisplayBasic(false)} className="dealbtn">No</Button>
      </div>
    </Dialog>
  </>
}
const handleKeyPress = (e) => {
  // Prevent space character (key code 32) from being entered
  if (e.key === ' ') {
    e.preventDefault();
  }
};
const handlePaste = (e) => {
  // Prevent paste operation
  e.preventDefault();
};

  return (
    <div className="mainHeader">
    <Row className="mainlogin">
    <Col className="loging">
      <Galleria className="logingallery" value={images} responsiveOptions={responsiveOptions} numVisible={1} circular autoPlay transitionInterval={5000}
        item={itemTemplate}
        // caption={this.caption}
        thumbnail={false} showThumbnails={false} showThumbnailNavigators={false} showIndicators changeItemOnIndicatorHover showIndicatorsOnItem   />
      </Col>
     
       <Col  className="loginf" xs={12} md={5}>
          <form autoComplete="off" onSubmit={(e) => validatePassword(e)}>
            <div className="image">
              <img src="/images/lattest/nedl_rgb.png" alt="logo" />
            </div>
            {loginerror === true ? 
          <ToastComponent
          severity="error"
      detail={loginerrormsg}
           /> : null }
             {showlogoutMessage === true ? 
          <ToastComponent
          severity="success"
      detail="Logged Out Successfully"
           /> : null }
            <div className="p-field Reset">
                <div className="forgotp">
              <label htmlFor="password" className="inputn">Password</label>
            <Link
              to="/forgotpassword"
              className="fpwd"
              style={{
                color: "#44546a",
                fontSize: "14px",
                fontWeight:"bolder",
                fontFamily: "Raleway Regular",
                textDecoration: "none",
              }}
            >
              Forgot Password ?
            </Link>
            </div>
            
                <Password className="passwordlogin"
                value={password}
                placeholder="Enter Password"
                name="password"
                onChange={(e) => setPassword(e.target.value)}
                 feedback={false}
                 onKeyPress={handleKeyPress}  // Attach the key press handler
                 onPaste={handlePaste}
                 toggleMask />
               <InputText className="inputlogin"
                    name="otp"
                    placeholder="Enter the OTP sent to your registered email"
                    id="otp"
                    value={loginotp}
                    autoComplete="off"
                    required
                    keyfilter="int"
                    onChange={(e) => setLoginOtp(e.target.value)}
                  />
               {pwderrormsg ? <label className="error">Invalid Password</label> : null}
               <Link
                to="/"
                className="fpwd"
                style={{
                  color: "#44546A",
                  fontSize: "12px",
                  fontWeight:"600",
                  fontFamily: "Raleway Regular",
                  textDecoration: "none",
                  display:"flex",
                  justifyContent:"flex-end",
                  marginTop:"10px"
                }}>
                Back to Login
              </Link>
              </div>
              {submitUser ? 
          <div className="spinnerAlign">
                <ProgressSpinner
                  style={{ width: "40px", height: "40px" }}
                  strokeWidth="5"
                />
              </div> :
            <Button id="loginSubmit" className="btnLogin" type="submit" label="LOGIN" /> 
            }   
            {/* <div className="poweredBy">
          <b>Powered by</b>
          <img className="goldimg" src="./images/FG.png" alt="Goldfinch" style={{width:"100px", height:"22px"}} />
           </div> */}
          </form>
          {newtabDialogue()}
        </Col>   
    </Row>
  </div>
  );
}

export default VerifyPassword;