import HighchartsReact from 'highcharts-react-official'
import React, { useEffect, useState } from 'react'
import * as Highcharts from 'highcharts';
import axios from "axios";
import moment from 'moment';


const DatahubAreaChart = ({userToken,data}) => {
const [avgRentData,setAvgRentData]= useState([]);
const [avgRentDataMinMax,setAvgRentDataMinMax]= useState([]);
const [date,setDate]= useState([]);
useEffect(()=>{
  getAvgComparableRent();
},[])
  const getAvgComparableRent = async () =>{
    console.log(data,"prop name")
    await axios.get(`${process.env.REACT_APP_DATAHUB_AVERAGE}?property=${data}`,
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((data) => {
        if (data?.status === 200) {       
        let i = data.data;
        let d = i.all;
        let chartDate = [];
        let avgrent = [];
        d?.map((a)=>{
          let year = moment(a.year_quarter).format("yyyy");
          let month = moment(a.year_quarter).format("MM");
          let formattedDate = month === "01" ? `Q1 ${year}` : month === "04" ? `Q2 ${year}` : month === "07" ? `Q3 ${year}` : `Q4 ${year}`;
          chartDate.push(formattedDate);
          avgrent.push(a.avg_comparable_rent)
          // chartData.push([{"date" : formattedDate, "rent" : a.avg_comparable_rent}])
        })
          setAvgRentData(avgrent) 
          const minValue = Math.min(...avgrent);
          const maxValue = Math.max(...avgrent);
          setAvgRentDataMinMax([minValue,maxValue])
          setDate(chartDate)  
        }
      })
  
  }
  let height = (window.innerHeight/5);
  let max = (window.innerHeight/7);
  let width = window.innerWidth < 500 ? window.innerWidth - 20 : (window.innerWidth / 2)   ;
 

  const dd = {
  chart: {
    height:height,
    width:width,
    //  height: 27 + '%',
     alignTicks: true,
    // width:width,
  },
credits: {
    enabled: false
  },
title: null,
xAxis: [{
    enabled: false,
    categories: date,
    crosshair: true,
    crosshair: {
        width: 2,
        zIndex: 0,
        color: "#ED7D31",
        dashStyle: 'shortdot'
      },
    // title:{
    //     enabled:false,
    //     text: 'Months',
    // }
}],
yAxis:{
    title: {
      enabled:false,
        // text: 'Count',    
    },
    min:avgRentDataMinMax[0],
    max:avgRentDataMinMax[1],
    tickAmount: 5,
    labels: {
      formatter: function() {
          return '$' + Highcharts.numberFormat(this.value, 0, '', ',');
      }
  }
    
    // tickInterval: 100
} ,
    
tooltip: {
    shared: true,
    // pointFormat: '<b>${point.y}</b>',
    formatter: function() {
      // Format tooltip content
      let tooltipText = '<b>' + this.x + '</b><br/>';
      this.points.forEach(point => {
          tooltipText += '$' + Highcharts.numberFormat(point.y, 0, '.', ',') + '<br/>';
      });
      return tooltipText;
  },
    style: {
      color: '#303030',
      fontWeight: '400',
      fontFamily:'Calibri',
      fontSize:'11px'

  },
//   formatter: function() {
//     return  '$' + Highcharts.numberFormat(point.y, 0, '', ',');
// }
},
plotOptions: {
    series: {
        marker: {
            enabled: false,
            lineColor:'#ED7D31',
            lineWidth:1,
            radius:2
        }
    }
},
legend:{
    enabled:false,
    itemStyle: {
      color: '#303030',
      fontWeight: '600',
      fontFamily: 'Raleway Regular',
      fontSize:'9px',
     
  },
  verticalAlign:'top',
  align:'right',
  // fontFamily:'Raleway Regular',
  // fontSize:'11px'
  },
series: [{
    name: '',
    type: 'area',
    lineColor:'#ED7D31',
    color: {
        linearGradient: {
          x1: 0,
          x2: 0,
          y1: 0,
          y2: 1
        },
        stops: [
          [0, '#fae2d1'],
          [1, '#DFDFDF00']
      ]
      },
    data:avgRentData,
    order: 2,
    events: {
        legendItemClick: function() {
          return false;
        },
        // stickyTracking: true,
        // mouseOver: function() {

        //   this.update({
        //     marker: {
        //       enabled: true,
        //       radius: 3,
        //       fillColor: 'white',
        //       border:'1px solid red'
        //     }
        //   })
        // },
        // mouseOut: function() {

        //   this.update({
        //     marker: {
        //       enabled: false
        //     }
        //   })
        // }
    }
       

}]
}


  return (
    <>

    <div style={{flexDirection:"column"}} className="map-container dflex w100">
    <div style={{marginTop:"5px",marginBottom:"0px"}} className="vddatatitle tl">AVERAGE COMPARABLE RENTS</div>
      <HighchartsReact className="barcomp"
      highcharts={Highcharts}
      options={dd}
    />
    </div>
    </>
  )
}

export default DatahubAreaChart;