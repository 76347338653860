import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import "./completeForm.css";
import HeaderLogo from "./HeaderLogo";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { Elements } from "@stripe/react-stripe-js";
import { Route } from "react-router-dom";
import CompletePage from "./CompletePage";
import Loader from "../../components/AAANEWCOMPONENT/Loader/Loader";
import { PaymentProvider } from "./PaymentProvider";
import PaymentProviderWrapper from "./PaymentProviderWrapper";
import axios from "axios";
import generator from "generate-password";
import { useNavigate } from 'react-router-dom';

export default function CheckoutForm({ firstName,
  lastName,
  email,
  subFor,
  selectPlan,
  priceToDisplay,
  selectedData,
  msaRegions,
  clientSecret,
  appearance,
loader,stripeTestPromise}) {
  console.log("checkoutFormloaded")
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [rtc,setRoutetoCompletePage] = useState(false);
  const [status, setStatus] = useState("default");
  const [intentId, setIntentId] = useState(null);
  const [successToast,setSuccessToast]=useState(false);
  const [addUserError,setAddUserError]=useState([]);
  const [addSubError,setAddSubError]=useState([]);
  const [addUserErrorToast,setAddUserErrorToast]=useState(false);
  const [addSubErrorToast,setAddSubErrorToast]=useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);
    const {error,paymentIntent} = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "http://localhost:3000/complete",
      },
      redirect:'if_required',
    });
    if(error !== undefined)
    {
      if (error.type === "card_error" || error.type === "validation_error") {
          setMessage(error.message);
          console.log(error.message,"card error")
        } else {
          setMessage("An unexpected error occurred.");
          console.log("An unexpected error occurred.","payment error")
  
        }
       console.log(error)
    }
    if (!paymentIntent) {
      return;
    }
    if (paymentIntent.status === 'succeeded') {
      console.log("payment succeeded");
      handleUserSubmit(paymentIntent,paymentIntent.status,paymentIntent.id);
    }

    setRoutetoCompletePage(true);
  };
  const handleUserSubmit = (paymentDetails,statusData,id) => {
    const newItem = {
    email: email,
    password: generator.generate({
      length: 10,
      numbers: true
    }),
    firstName: firstName,
    lastName: lastName,
    org: 73,
    role: 2,
    paymentintentid:id,
    payment:paymentDetails
  };
  axios
    .post(`${process.env.REACT_APP_USER_SIGNUP}`, newItem, 
    //   {
    //   headers: {
    //     Authorization: `Bearer ${userToken}`,
    //   },
    // }
  )
    .then((res) => {
      if(res.status === 200){
        let i = res.data;
        let userid = i.id;
      handleAddSub(userid,statusData,id);
      setIsLoading(false);
    }
    })
     .catch((err) =>{
    let error = err.response.data.error;
    console.log(err.response.data,"user error check")
    setAddUserErrorToast(true);
    setTimeout(function () {
      setAddUserErrorToast(false);
    }, 3000); 
    setIsLoading(false);
    setAddUserError(error.message);
    navigate("/complete",
      { state: 
       {
         status: statusData,
         intentId: id,
         successToast:false,
         addUserErrorToast:true,
         addSubErrorToast:false,
         email:email,
         } 
     });

    });    

};
const getNextMonthDate = (currentDate) => {
let date = new Date(currentDate);
let nextMonth = date.getMonth() + 1;
if (nextMonth > 11) {
  nextMonth = 0; // Reset to January
  date.setFullYear(date.getFullYear() + 1); // Increment year
}
date.setMonth(nextMonth);
if (date.getDate() !== currentDate.getDate()) {
  date.setDate(0); // Set to the last day of the previous month
}
return date.toISOString()
};
const getNextYearDate = (currentDate) => {
let date = new Date(currentDate);
date.setFullYear(date.getFullYear() + 1);
return date.toISOString()
};
const handleAddSub = (userid,statusData,id) =>{
let currentDate = new Date();
let selectPlanName = ''
let endDate = ''
if(selectPlan === "1"){
 endDate = getNextMonthDate(currentDate)
 selectPlanName = "Monthly"
}
 if(selectPlan === "3"){
endDate = getNextYearDate(currentDate)
selectPlanName = "Yearly"
}
let u = ''
if(userid !== null){
  let userIDData = [];
  userIDData.push(userid)
  u = {"users": userIDData}
}

let subData = ''
if(subFor.id === 1){
  let msacode = selectedData.map((a)=> a.id);
  subData = {"MSA": msacode}
  }
if(subFor.id === 2){
  let regionname = selectedData.map((a) => a.name);
  const filtered = msaRegions.filter(d => regionname.includes(d.region));

  let msacode = filtered.map((a)=> a.msa_code);
  subData = {"region":regionname,"MSA":msacode}
  }
if(subFor.id === 3){
subData = {"ALL":"ALL"}
}   
let  subdataItem = {
    org:73,
    typeId: subFor.id*1,
    sub_data: subData,
    subscriptionName: email,
    userLimit:1,
    users:u,
    endDate: endDate,
    subFor:selectPlanName
}
axios
.post(`${process.env.REACT_APP_SUBSCRIPTION_DATA}`,subdataItem,
//    {
//   headers: {
//     Authorization: `Bearer ${userToken}`,
//   },
// }
)
.then((res) => {
  if(res.status === 200){
  let i = res.data;
  setSuccessToast(true);
  setTimeout(function () {
   setSuccessToast(false);
 }, 3000);
 setIsLoading(false);
 navigate("/complete",
  { state: 
   {
     status: statusData,
     intentId: id,
     successToast:true,
     addUserErrorToast:false,
     addSubErrorToast:false,
     email:email,
     } 
 });
}
})
.catch((err) =>{
let error = err.response.data.error;
console.log(err.response.data,"sub error check")
setAddSubErrorToast(true);
setTimeout(function () {
  setAddSubErrorToast(false);
}, 3000); 
setAddSubError(error.message);
setIsLoading(false);
navigate("/complete",
  { state: 
   {
     status: statusData,
     intentId: id,
     successToast:false,
     addUserErrorToast:false,
     addSubErrorToast:true,
     email:email,
     } 
 });
}); 
}
  const paymentElementOptions = {
    layout: "tabs"
  }
  let subDetailPlanName = selectPlan === "1" ? "Monthly" : "Annual";
  let subDetailName = subFor.name === "All" ? "National" : subFor.name;
  let fm = selectedData !== null ? selectedData.map(a => a.name) : null;
  let SelectedDetails = subFor.name === "Region" ? fm : subFor.name === "MSA" ? fm : null;
  const finalAmount = selectedData === null ? priceToDisplay : priceToDisplay*selectedData?.length;

  console.log(SelectedDetails,"selected")
  return (
  
    <>
    <div className="payment-main-bg">
    {/* <HeaderLogo /> */}
    <div className="checkout-main-div">
      <div className="checkout-left">
       <div className="checkout-left-header">Summary</div>
       <div className="checkout-left-main-content">
        <label>Total Due (USD) </label>
        <label className="checkout-content">{`$${finalAmount}`}</label>
        </div>
       <div className="checkout-left-subheader">Subscription Details</div>
       <label className="selectionlistname">Selected Plan</label>
       <div className="checkout-left-main-content-plan">{`${subDetailName} ${subDetailPlanName}`}</div>
       {subFor.name !== "All" ?
       <div className="checkout-left-main-content-list">
       <div className="selectionlistname">{`Selected ${subFor.name}`}</div>
       <div className="selectionlist">
       <ul style={{margin:"0",padding:"0",lineHeight:"16px"}}>
        {SelectedDetails.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
        {/* {`${SelectedDetails}`} */}
        </div>
       </div>
       :null}
      </div>
      <form className="payment-complete-form" id="payment-form" onSubmit={handleSubmit}>
        <label className="checkout-heading">Contact info</label>
        <div className="dropdownPropertyBasicCheckout">
                        <div className="dropdownContentCheckout">
                          <label className="checkoutlabelstyle">First Name<span style={{fontSize:"18px",fontWeight:"bolder"}}>*</span></label>
                          <InputText value={firstName} disabled />
                        </div>
                        <div className="dropdownContentCheckout">
                          <label className="checkoutlabelstyle">Last Name<span style={{fontSize:"18px",fontWeight:"bolder"}}>*</span></label>
                       <InputText value={lastName} disabled  />
                        </div>
                      </div>
                      <div className="dropdownPropertyBasicCheckout">
                      <div className="dropdownContentCheckout">
                          <label className="checkoutlabelstyle">Email ID<span style={{fontSize:"18px",fontWeight:"bolder"}}>*</span></label>
                          <InputText value={email}  disabled />
                        </div>
                      </div>
        <Divider/>
        <label className="checkout-heading">Payment info</label>
        <PaymentElement id="payment-element" className="paymentElement" options={paymentElementOptions} />
        <div id="dpm-annotation">
        <p>
        By submitting this transaction, you agree to the&nbsp;
          {/* Payment methods are dynamically displayed based on customer location, order amount, and currency.&nbsp; */}
          <a href="https://nedl.us/terms-of-use/" target="_blank" rel="noopener noreferrer" id="dpm-integration-checker">terms of use</a>
          &nbsp;and the terms of purchase.
        </p>
      </div>
        <button disabled={isLoading || !stripe || !elements} id="submit">
          <span id="button-text">
            {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
          </span>
        </button>
        {message && <div id="payment-message">{message}</div>}

      </form>
     
      </div>
      </div>
     
      </>
     
  );
}