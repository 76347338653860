
import React, { useState, useEffect } from "react";
import "./loginold.css";
import FormMiddleware from "../middlewares/FormMiddleware";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Link } from "react-router-dom";
import { sendUsername } from "../middlewares/User";
import { PhotoService } from '../service/PhotoService';
import { Galleria } from 'primereact/galleria';
import { Password } from 'primereact/password';
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import ToastComponent from "../components/AAANEWCOMPONENT/toast";
import { ProgressSpinner } from "primereact/progressspinner";


const ForgotPasswordNew = () => {
    const[images,setImages] = useState([]);
    const[username,setUsername]=useState("");
    const[usernamefield,setUsernameField]=useState("");
    const[resetkey,setResetKey]=useState("");
    const[newpassword,setNewPassword]=useState("");
    const[confirmpassword,setConfirmPassword]=useState("");
    const[reseterror,setResetError]=useState(false);
    const[mailerrormsg,setMailErrorMsg]=useState(false);
    const[reseterrormsg,setResetErrorMsg]=useState(false);
    const[pwdMatch,setPwdMatchError]=useState(false);
    const[submitUser,setSubmitUser] = useState(true);
  const galleriaService = new PhotoService();
  const navigate = useNavigate();
  const[toast,setToast] = useState(false);
  const[resettoast,setResetToast] = useState(false);
  const[submitResetUser,setSubmitResetUser] = useState(false);
  const[submitUserEmail,setSubmitUserEmail] = useState(false);
  const[usererror,setUserError]=useState(false);
  const[usererrormsg,setUserErrorMessage]=useState("");

   const responsiveOptions = [
    {
      breakpoint: '1024px',
      numVisible: 5
    },
    {
      breakpoint: '768px',
      numVisible: 3
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];

  const sendMail = async (e) => {
    e.preventDefault();
    setSubmitUserEmail(true);
    const newItem = {
      "email": username,
    };
      if (username !== '') {
        // await axios.get(`${process.env.REACT_APP__FORGOTPWD}?email=${username}`
          await axios.post(`${process.env.REACT_APP__FORGOTPWD}`, newItem,

        ).then((data) => {
          if(data.status === 200){
          setUserError(false);
          setSubmitUser(false);
          setSubmitUserEmail(false);
          }
          return data
        })
            // .then((res) => {return "mail sent"}
            // )
            .catch((err) => {
              let error = err.response.data.error;
              setUserError(true);
              if(error.message === "Invalid email / Email not available on Database")
                {setUserErrorMessage("Invalid email / Email not available");}
                else{setUserErrorMessage(error.message);}
              console.log(err); setSubmitUserEmail(false)}
            );
        setUsername("");
      }else{
        setMailErrorMsg(true);
        setSubmitUserEmail(false);
      }
  };

  const validateFormInput = (e) => {
    e.preventDefault();
    setSubmitResetUser(true);
      if (newpassword === confirmpassword) {

          const resetnow = {
            
                password: newpassword,
                resetkey: resetkey
                
             };
          axios
            .patch(`${process.env.REACT_APP__FORGOTPWD_RESET}`, resetnow)
            .then((res) =>{
              setSubmitResetUser(false);
              if(res.status === 200){
                if(res.data === "Invalid reset key"){
                  setResetError(true);
                  setResetErrorMsg("Invalid OTP")
                  setTimeout(function () {
                    setResetError(false);
                 }, 5000); 
                  setSubmitResetUser(false)
                }
                else{
              setResetToast(true);
              setTimeout(function () {
                navigate('/')
             }, 1000); 
             setResetKey("");
             setNewPassword("");
             setConfirmPassword("");
            }
            }
            })
         
        
      
      }
      else{
        setSubmitResetUser(false);  
      }
  };
 const itemTemplate = (item) => {
    console.log(item)
    return <img className="gi" src={item.image} alt={item.name} />;
  }
  useEffect(() => {
    galleriaService.getImages().then(data => setImages(data));
}, [])
  const caption = (item) => {
    return (
      <React.Fragment>
        <h4 className="p-mb-2">{item.title}</h4>
        <p>{item.alt}</p>
      </React.Fragment>
    );
  }

  const handlePasswordMatchCheck = (cpwd) =>{
    setConfirmPassword(cpwd)
    if(newpassword !== cpwd){
      setPwdMatchError(true)
     }
    else{
      setPwdMatchError(false)
    }
  }
  const handleKeyPress = (e) => {
    // Prevent space character (key code 32) from being entered
    if (e.key === ' ') {
      e.preventDefault();
    }
  };
  const handlePaste = (e) => {
    // Prevent paste operation
    e.preventDefault();
  };
  const handleNewPasswordMatchCheck = (pwd) =>{
    setNewPassword(pwd);
    if (confirmpassword.length  === 0){
      setPwdMatchError(false)
    }
    else{
      if(confirmpassword !== pwd){
        setPwdMatchError(true)
       }else{
         setPwdMatchError(false)

       }
    }
  }
  return (
    <div className="mainHeader">
    <Row className="mainlogin">
    <Col className="loging">
      <Galleria className="logingallery" value={images} responsiveOptions={responsiveOptions} numVisible={1} circular autoPlay transitionInterval={5000}
        item={itemTemplate}
        // caption={this.caption}
        thumbnail={false} showThumbnails={false} showThumbnailNavigators={false} showIndicators changeItemOnIndicatorHover showIndicatorsOnItem   />
      </Col>
      {submitUser === true ? 
      <>
      <Col className="loginf" xs={12} md={5}>
          <form autoComplete="off" onSubmit={(e) => sendMail(e)}>
            <div className="image">
              <img src="/images/lattest/nedl_rgb.png" alt="logo" />
            </div>
            {toast === true ? 
          <ToastComponent
          severity="success"
          detail="Mail Sent Successfully"
           /> : null }
            {usererror === true ? 
          <ToastComponent
          severity="error"
          detail={usererrormsg}
           /> : null }
            <div className="logintext">
            <p>Enter the email address associated with your account and we'll send you a link to reset your password</p>
            </div>
                <div className="p-field Reset">
                <InputText className="inputlogin"
                    name="name"
                    placeholder="Email Address"
                    id="name"
                    value={username}
                    autoComplete="off"
                    required
                    onChange={(e) => {setUsername(e.target.value);setUsernameField(e.target.value);}}
                    type="email"
                  />
               
             {mailerrormsg ? <label className="error">Enter Valid UserName</label> : null}
          </div>
          {submitUserEmail ? 
          <div className="spinnerAlign">
                <ProgressSpinner
                  style={{ width: "40px", height: "40px" }}
                  strokeWidth="5"
                />
              </div> :
            <Button id="loginSubmit" className="btnLogin" type="submit" label="Submit" />}
            <div className="fpwdl" >
                Did you remember your password?
                  <Link
                    to="/"
                    className="fpwd"
                    style={{
                      color: "#44546a",
                      fontSize: "12px",
                      fontWeight:"bolder",
                      fontFamily: "Raleway Regular",
                      textDecoration: "none",
                    }}>
                    Login
                  </Link>
                </div>
            {/* <div className="poweredBy">
          <b>Powered by</b>
          <img className="goldimg" src="./images/FG.png" alt="Goldfinch" style={{width:"100px", height:"22px"}} />
           </div> */}
          </form>
        </Col>
      </>
      :
      <>
       <Col className="loginf" xs={12} md={5}>
          <form autoComplete="off" onSubmit={(e) => validateFormInput(e)}>
            <div className="image">
              <img src="/images/lattest/nedl_rgb.png" alt="logo" />
            </div>
            {resettoast === true ? 
          <ToastComponent
          severity="success"
          detail="Password Reset Successful"
           /> : null }
           {reseterror === true ? 
          <ToastComponent
          severity="error"
      detail={reseterrormsg}
           /> : null }
            <div className="logintext">
              <p style={{fontSize:"16px"}}>Reset password</p>
            </div>
                <div className="p-field Reset">
                <InputText className="inputlogin"
                    name="name"
                    id="name"
                    autoComplete="off"
                    placeholder={usernamefield}
                    required
                    readOnly
                    type="text"
                  />
                <InputText className="inputlogin"
                    name="rotp"
                    placeholder="Enter the code sent to your registered email"
                    id="rotp"
                    value={resetkey}
                    autoComplete="off"
                    required
                    onChange={(e) => setResetKey(e.target.value)}
                  />
                {/* <label htmlFor="password" className="inputn">Password</label> */}
            {/* <Password className="passwordlogin"
            value={currentpassword}
            placeholder="Enter Current Password"
            name="currentpassword"
            onChange={(e) => setCurrentPassword(e.target.value)}
            feedback={false}
             toggleMask /> */}
          
            <Password className="passwordlogin"
            value={newpassword}
            placeholder="Enter New Password"
            name="newpassword"
            required
            onChange={(e) => handleNewPasswordMatchCheck(e.target.value)}
            onKeyPress={handleKeyPress}  // Attach the key press handler
            onPaste={handlePaste}
            feedback={false}
             toggleMask />
          {/* </div> */}
          {/* <div className="forgotp">
          <label htmlFor="password" className="inputn">Re-enter Password</label>
        </div> */}
            <Password className="passwordlogin"
            value={confirmpassword}
            placeholder="Re-enter New Password"
            name="confirmpassword"
            required
            onChange={(e) => handlePasswordMatchCheck(e.target.value)}
            onPaste={handlePaste}
            feedback={false}
            onKeyPress={handleKeyPress}  // Attach the key press handler
            
             toggleMask />
             {pwdMatch ? <label className="error">Password doesn't Match</label>:  null}
              <Link
                to="/"
                className="fpwd"
                style={{
                  color: "#44546A",
                  fontSize: "12px",
                  fontWeight:"600",
                  fontFamily: "Raleway Regular",
                  textDecoration: "none",
                  display:"flex",
                  justifyContent:"flex-end",
                  marginTop:"10px"
                }}>
                Back to Login
              </Link>
          </div>
          {submitResetUser ? 
          <div className="spinnerAlign">
                <ProgressSpinner
                  style={{ width: "40px", height: "40px" }}
                  strokeWidth="5"
                />
              </div> :
            <Button id="loginSubmit" className="btnLogin" type="submit" label="Submit" />}
            {/* <div className="poweredBy">
          <b>Powered by</b>
          <img className="goldimg" src="./images/FG.png" alt="Goldfinch" style={{width:"100px", height:"22px"}} />
           </div> */}
          </form>
        </Col>
      </>}
        
    </Row>
  </div>
  );
}

export default ForgotPasswordNew;