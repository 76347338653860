
import React, { useState, useEffect } from "react";
import "./loginold.css";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Link ,useParams} from "react-router-dom";
import { PhotoService } from '../service/PhotoService';
import { Galleria } from 'primereact/galleria';
import { Password } from 'primereact/password';
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import ToastComponent from "../components/AAANEWCOMPONENT/toast";
import { ProgressSpinner } from "primereact/progressspinner";


const AddPassword = () => {
  const[addpwderror,setAddPwdError]=useState(false);
  const[addpwderrormsg,setAddPwdErrorMessage]=useState("");
    const[images,setImages] = useState([]);
    const[username,setUsername]=useState("");
    const[resetkey,setResetKey]=useState("");
    const[newpassword,setNewPassword]=useState("");
    const[confirmpassword,setConfirmPassword]=useState("");
    const[pwdMatch,setPwdMatchError]=useState(false);
  const galleriaService = new PhotoService();
  const navigate = useNavigate();
  const[resettoast,setResetToast] = useState(false);
  const useremail = sessionStorage.getItem('useremail');
  const[submitUser,setSubmitUser] = useState(false);
  const[reseterror,setResetError]=useState(false);
  const[reseterrormsg,setResetErrorMsg]=useState(false);

   const responsiveOptions = [
    {
      breakpoint: '1024px',
      numVisible: 5
    },
    {
      breakpoint: '768px',
      numVisible: 3
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];
  const itemTemplate = (item) => {
    return <img className="gi" src={item.image} alt={item.name} />;
  }
  useEffect(() => {
    galleriaService.getImages().then(data => setImages(data));
}, [])
  const caption = (item) => {
    return (
      <React.Fragment>
        <h4 className="p-mb-2">{item.title}</h4>
        <p>{item.alt}</p>
      </React.Fragment>
    );
  }

 
  const validateFormInput = (e) => {
    e.preventDefault();
    setSubmitUser(true);
      if (newpassword === confirmpassword) {
          const resetnow = {
            otp: resetkey*1, 
              email:useremail,
              password:newpassword
           
           
             };
          axios
            .post(`${process.env.REACT_APP_ADD_PASSWORD}`, resetnow, 
            )
            .then((res) =>  {
              if(res.status === 200){
                setSubmitUser(false);             
                setResetToast(true);
                setTimeout(function () {
                  navigate('/')
               }, 5000); 
               setResetKey("");
               setNewPassword("");
               setConfirmPassword("");
              }
             
              
            }  
            )
            .catch((err) =>{
              let error = err.response.data.error;
              setResetError(true);
                  setResetErrorMsg(error.message)
                  setTimeout(function () {
                    setResetError(false);
                 }, 5000); 
                 setSubmitUser(false);             
              });   
     
  }else{
    setSubmitUser(false);             
  }
}
  const handlePasswordMatchCheck = (cpwd) =>{
    setConfirmPassword(cpwd)
    if(newpassword !== cpwd){
      setPwdMatchError(true)
     }
    else{
      setPwdMatchError(false)
    }
  }
  const handleNewPasswordMatchCheck = (pwd) =>{
    setNewPassword(pwd)
    if (confirmpassword.length  === 0){
      setPwdMatchError(false)
    }
    else{
      if(confirmpassword !== pwd){
        setPwdMatchError(true)
       }else{
         setPwdMatchError(false)

       }
    }
  }
  const handleKeyPress = (e) => {
    // Prevent space character (key code 32) from being entered
    if (e.key === ' ') {
      e.preventDefault();
    }
  };
  const handlePaste = (e) => {
    // Prevent paste operation
    e.preventDefault();
  };
 
  return (
    <div className="mainHeader">
    <Row className="mainlogin">
    <Col className="loging">
      <Galleria className="logingallery" value={images} responsiveOptions={responsiveOptions} numVisible={1} circular autoPlay transitionInterval={5000}
        item={itemTemplate}
        // caption={this.caption}
        thumbnail={false} showThumbnails={false} showThumbnailNavigators={false} showIndicators changeItemOnIndicatorHover showIndicatorsOnItem   />
      </Col>
     
        <>
        <Col className="loginf" xs={12} md={5}>
          <form autoComplete="off" onSubmit={(e) => validateFormInput(e)}>
            <div className="image">
              <img src="/images/lattest/nedl_rgb.png" alt="logo" />
            </div>
            {addpwderror === true ? 
          <ToastComponent
          severity="error"
          detail={addpwderrormsg}
           /> : null }
            {resettoast === true ? 
          <ToastComponent
          severity="success"
      detail="Password Reset Successful"
           /> : null }
             {reseterror === true ? 
          <ToastComponent
          severity="error"
      detail={reseterrormsg}
           /> : null }
            <div className="logintext">
              <p style={{fontSize:"16px"}}>Add User Password</p>
            </div>
                <div className="p-field Reset">
                <InputText className="inputlogin"
                    name="name"
                    id="name"
                    autoComplete="off"
                    placeholder={useremail}
                    required
                    readOnly
                    type="text"
                  />
                <InputText className="inputlogin"
                    name="aotp"
                    placeholder="Enter the OTP sent to your registered email"
                    id="aotp"
                    value={resetkey}
                    autoComplete="off"
                    required
                    onChange={(e) => setResetKey(e.target.value)}
                    keyfilter="int"
                  />
            {/* <div className="forgotp">
          <label htmlFor="password" className="inputn">New Password</label>
        </div> */}
            <Password className="passwordlogin"
            value={newpassword}
            placeholder="Enter New Password"
            name="newpassword"
            onChange={(e) => handleNewPasswordMatchCheck(e.target.value)}
            feedback={false}
            onKeyPress={handleKeyPress}  // Attach the key press handler
            onPaste={handlePaste}
             toggleMask />
          {/* </div> */}
          {/* <div className="forgotp">
          <label htmlFor="password" className="inputn">Re-enter Password</label>
        </div> */}
            <Password className="passwordlogin"
            value={confirmpassword}
            placeholder="Re-enter New Password"
            name="confirmpassword"
            onChange={(e) => handlePasswordMatchCheck(e.target.value)}
            feedback={false}
            onKeyPress={handleKeyPress}  // Attach the key press handler
            onPaste={handlePaste}
             toggleMask />
             { pwdMatch ? <label className="error">Password doesn't Match</label>:  null}
              <Link
                to="/"
                className="fpwd"
                style={{
                  color: "#44546A",
                  fontSize: "12px",
                  fontWeight:"600",
                  fontFamily: "Raleway Regular",
                  textDecoration: "none",
                  display:"flex",
                  justifyContent:"flex-end",
                  marginTop:"10px"
                }}>
                Back to Login
              </Link>
          </div>
          {submitUser ? 
          <div className="spinnerAlign">
                <ProgressSpinner
                  style={{ width: "40px", height: "40px" }}
                  strokeWidth="5"
                />
              </div> :
            <Button id="loginSubmit" className="btnLogin" type="submit" label="Submit" />}
            {/* <div className="poweredBy">
          <b>Powered by</b>
          <img className="goldimg" src="./images/FG.png" alt="Goldfinch" style={{width:"100px", height:"22px"}} />
           </div> */}
          </form>

        </Col>
      </>
        
    </Row>
  </div>
  );
}

export default AddPassword;