import React, { useState, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { RadioButton } from 'primereact/radiobutton';            
import axios from "axios";
import generator from "generate-password";
import { ListBox } from 'primereact/listbox';
import { Chips } from 'primereact/chips';
import ToastComponent from "../../components/AAANEWCOMPONENT/toast";
import './StripeForm.css'
import PaymentForm from "./PaymentForm";
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import SubscriptionForm from "./SubscriptionForm";
import PaymentUSMap from "./PaymentUSMap";
import { useLocation } from "react-router-dom";
import PageNotFound from "../PageNotFound";
import Loader from "../../components/AAANEWCOMPONENT/Loader/Loader";


const  AddSubUserForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {subamount,subplan,subsubscriptionFor,submsaMonthly,submsaYearly,subregionMonthly,subregionYearly,suballMonthly,suballYearly,clicked} = location.state || {};
  const { priceId } = useParams();
  const pricePresent = priceId !== undefined  ? Number(priceId) : null;
  const subforname = pricePresent !== null  && pricePresent === 1 || pricePresent !== null  && pricePresent === 4 ? "MSA" : pricePresent !== null  && pricePresent === 2 || pricePresent !== null  && pricePresent === 5 ? "Region" : pricePresent !== null  && pricePresent === 3 || pricePresent !== null  && pricePresent === 6 ? "All" : undefined;
  const amount = subamount !== undefined ? subamount : priceId;
  const subscriptionFor = subsubscriptionFor !== undefined ? subsubscriptionFor : subforname;
  const plan =  pricePresent !== null && pricePresent === 1 || pricePresent !== null && pricePresent === 2 || pricePresent !== null &&  pricePresent === 3 ? "monthly"
  : pricePresent !== null  && pricePresent === 4 ||pricePresent !== null && pricePresent === 5 ||pricePresent !== null && pricePresent === 6 ? "yearly"
  : undefined;
  const msaMonthly = suballMonthly !== undefined ? submsaMonthly : 99;
  const regionMonthly = subregionMonthly !== undefined  ? subregionMonthly : 199;
  const allMonthly=  suballMonthly !== undefined ? suballMonthly : 299;
  const msaYearly =  submsaYearly !== undefined ? submsaYearly : 950;
  const regionYearly =  subregionYearly !== undefined ? subregionYearly : 1910;
  const allYearly = suballYearly !== undefined ? suballYearly : 2870;
  console.log(plan,clicked,priceId,pricePresent,subforname,amount,subscriptionFor,msaMonthly,msaYearly,regionMonthly,regionYearly,allMonthly,allYearly,"cehcl")
  const [selectedSubscriptionType,setSelectedSubscriptionType] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [emailspaceerror,setemailSpaceError]= useState(false);
  const [emailerror,setemailError]= useState(false);
  const [checked, setChecked] = useState([]);
  const [checkedRegion, setCheckedRegion] = useState([]);
  const [subscriptionTypes, setSubscriptionTypes] = useState([]);
  const [msaRegions, setMSARegions] = useState([]);
  const [msas, setMsas] = useState([]);
  const [selectedMsas, setSelectedMsas] = useState([]);
  let planNumber = plan === "yearly" ? "3" : "1";
  const [selectPlan, setSelectPlan] = useState(planNumber);
  const [filter, setFilter] = useState('');
const [priceToDisplay,setPriceToDisplay] = useState(amount);
const [finalAmt,setFinalAmt] = useState(null);
const [finalSubscriptionFor,setFinalSubscriptionFor]=useState(null);
const [finalPlan,setFinalPlan]=useState(null);
const [finalSelectedData,setFinalSelectedData]=useState(null);
const [showPaymentForm, setShowPaymentForm] = useState(false);

const [showPageNotFound, setShowPageNotFound] = useState(false);

useEffect(()=>{
handlePageNotFound();
},[])
const handlePageNotFound = () =>{
  if(priceId === "1" || priceId === "2" || priceId === "3" || priceId === "4" || priceId === "5" || priceId === "6" )
  {
    setShowPageNotFound(false);
  }else{
    setShowPageNotFound(true);
  }
  }

  useEffect(()=>{
    getSubscriptionTypes();
    getMSARegions();
   
  },[])

 
  useEffect(()=>{
    if(selectedSubscriptionType !== null){
      setSelectedMsas([]);
      setFilter('');
      handleSubscriptionType(selectedSubscriptionType);
     
    }
   },[selectedSubscriptionType,subscriptionFor,msaRegions])  
   useEffect(()=>{
    if(selectedSubscriptionType !== null && selectedSubscriptionType.name === "MSA"){
      if(selectPlan === "1"){
        setPriceToDisplay(msaMonthly)
      }
      if(selectPlan === "3"){
        setPriceToDisplay(msaYearly)
      }
    }
    if(selectedSubscriptionType !== null && selectedSubscriptionType.name === "Region"){
      if(selectPlan === "1"){
        setPriceToDisplay(regionMonthly)
      }
      if(selectPlan === "3"){
        setPriceToDisplay(regionYearly)
      }
    }
    if(selectedSubscriptionType !== null && selectedSubscriptionType.name === "All"){
      if(selectPlan === "1"){
        setPriceToDisplay(allMonthly)
      }
      if(selectPlan === "3"){
        setPriceToDisplay(allYearly)
      }
    }
   },[selectedSubscriptionType,selectPlan,subscriptionFor])

 
  const handleProceedSubscription  = () => {
    const subFor = selectedSubscriptionType !== null ? selectedSubscriptionType : subscriptionFor;
    const selectedData = selectedMsas.length > 0 ? selectedMsas : null;
    setFinalSelectedData(selectedData);
    setFinalSubscriptionFor(subFor);
    setFinalAmt(priceToDisplay);
    setFinalPlan(selectPlan);
    setShowPaymentForm(true);
};
const getSubscriptionTypes = async () =>{
  await axios.get(`${process.env.REACT_APP_SUBSCRIPTION_TYPES}`,
    // {
    //          headers: {
    //            Authorization: `Bearer ${userToken}`,
    //          },
    //        }
  )
  .then((data) => {
    let dd = data.data;
    setSubscriptionTypes(dd);
    if(subscriptionFor !== undefined){
      const selectedSubType = dd.find(u => u.name.toLowerCase() === subscriptionFor.toLowerCase());
      setSelectedSubscriptionType(selectedSubType);
    }
})
}
const getMSARegions = async () =>{
await axios.get(`${process.env.REACT_APP_MSA_REGIONS}`,
  // {
  //          headers: {
  //            Authorization: `Bearer ${userToken}`,
  //          },
  //        }
)
.then((data) => {
  let dd = data.data;
  setMSARegions(dd); 
})
}
 
  const handleSubscriptionType = (value) =>{
    if(value.name === "MSA"){
      setCheckedRegion([]);
    }
    if(value.name === "Region"){
      setChecked([])
    }
    if(value.name === "All"){
      setChecked([])
      setCheckedRegion([])
    }
    setSelectedSubscriptionType(value);
    getdata();
  }
  const getdata = () => {
    let msaData = [];
    let regionData = [];
    if (selectedSubscriptionType.id === 1) {
        msaRegions?.forEach((a,index) =>{
          const msaItem ={
            id: a.msa_code,
                region: a.region,
                name: a.msa_name,
                selected:  false,
          }
          // if (index === 0) {
          //   msaItem.selected = true;
          // }
          msaData.push(msaItem)
        }
        );
        setMsas(msaData);
        setSelectedMsas(msaData.filter(msa => msa.selected)); 
    }

    if (selectedSubscriptionType.id=== 2) {
        msaRegions?.forEach((a) =>
            regionData.push({
                id: a.msa_code,
                name: a.region,
                msa_name: a.msa_name,
                selected:  false 
            })
        );

        let uniqueRegionName = [...new Map(regionData.map(regionData => [regionData.name, regionData])).values()];
        let sortedRegionName = uniqueRegionName.sort((a, b) => a.name.localeCompare(b.name));
        // if (sortedRegionName.length > 0) {
        //   sortedRegionName[0].selected = true;  
        // }
        setMsas(sortedRegionName);
        setSelectedMsas(sortedRegionName.filter(region => region.selected)); 
    }
   
};
const handleSelectionChange = (msa) => {
    if (msa.selected || selectedMsas.length < filteredMsas.length) {
        const updatedMsas = msas.map(item =>
            item.name === msa.name ? { ...item, selected: !item.selected } : item
        );

        if (msa.selected) {
            setSelectedMsas(selectedMsas.filter(item => item.name !== msa.name));
        } else {
            setSelectedMsas([...selectedMsas, { ...msa, selected: true }]);
        }
        setMsas(updatedMsas);
    }
};
const handleChipRemove = (removedMsa) => {
  const updatedMsas = msas.map(item => item.name === removedMsa[0].name ? { ...item, selected: false } : item);
  setMsas(updatedMsas);
  setSelectedMsas(selectedMsas.filter(item => item.name !== removedMsa.name));
};
const filteredMsas = msas?.filter(msa => msa.name.toLowerCase().includes(filter.toLowerCase()));
 
  const handleEmail = (e) => {
    const { value } = e.target;
    const hasSpaces = /\s/.test(value);
    const emailValidator = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const invalidEmail = !emailValidator.test(value);
    setemailError(invalidEmail);
    setEmail(value.toLowerCase());
    setemailSpaceError(hasSpaces);
  };
  const landingPageTemplate = (option) => {
    return (
      <div className="landing_page-item">
        <div>{option.name}</div>
      </div>
    );
  };
  const handleNavigateBack = () =>{
    if(clicked === undefined){
      window.location.href = 'https://nedl.us/multifamily-market/';
    }else{
      navigate('/subscription')
    }
  }
  return(
    <>
    {showPageNotFound === true ? <PageNotFound /> :
    <>
    {showPaymentForm === false ? 
    <div className="subscribeNowPage"
    //  style={{backgroundImage:"url('./images/lattest/bgimage.svg')"}}
     >
      <h4 style={{textAlign:"left",padding:"30px 60px"}}>
        <i onClick={handleNavigateBack} className="pi pi-chevron-circle-left backIcon">
        </i>Subscription Details</h4>
        {/* <div className="fillSubMainContainer"> */}
     <div className="panelContentPaymentAddSub">
    {/* {subaddtoast === true ? <ToastComponent severity="success" detail="Subscription Added Successfully" /> : null } */}
    {/* {updatedSubToast === true ? <ToastComponent severity="success" detail="Subscription Updated Successfully" /> : null} */}
        {msaRegions.length > 0 ?
        <div className="spform">
        {/* <h4 style={{color:"#FFFFFF",display:"flex",justifyContent:"center",alignItems:"flex-start"}}>Subscription Details</h4> */}
      <div className="addorsubscribepaymentform ">
                      <div className="spleft">
                      <div className="dropdownPropertyBasicPayment">
                        <div className="dropdownContentPayment">
                          <label className="labelstyle">First Name<span style={{color:"#FF0000",fontSize:"16px",fontWeight:"bolder"}}>*</span></label>
                          <InputText value={firstName} onChange={(e) => setFirstName(e.target.value)}
                            placeholder="Enter First Name" keyfilter="alpha" required />
                        </div>
                        <div className="dropdownContentPayment">
                          <label className="labelstyle">Last Name<span style={{color:"#FF0000",fontSize:"16px",fontWeight:"bolder"}}>*</span></label>
                       <InputText value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder="Enter Last Name" keyfilter="alpha" required />
                        </div>
                        <div className="dropdownContentPayment">
                          <label className="labelstyle">Email ID<span style={{color:"#FF0000",fontSize:"16px",fontWeight:"bolder"}}>*</span></label>
                          <InputText value={email} onChange={handleEmail} placeholder="Enter Email ID" required />
                          {email !== "" && emailerror || email !== "" && emailspaceerror?  <label className="paymentemailerror">Invalid Email</label> : null}
                        </div>
                      </div>
                        <div className="dropdownPropertyBasicPayment">
                    <div className="dropdownContentPayment" style={{width:"60%",display:"flex",flexDirection:"column",gap:"3px"}}>
                    <label className="labelstyle">Subscription Type<span style={{color:"#FF0000",fontSize:"16px",fontWeight:"bolder"}}>*</span></label>
                         <Dropdown
                         value={selectedSubscriptionType}
                         options={subscriptionTypes}
                         key="st"
                         onChange={(e) => handleSubscriptionType(e.value)}
                         optionLabel="name"
                         placeholder="Select Subcription Type"
                         itemTemplate={landingPageTemplate}
                       />
                       </div>
                      </div>
                      
                      <div className="dropdownPropertyBasicPayment">
                    <label className="labelstyle">Select Your Plan<span style={{color:"#FF0000",fontSize:"16px",fontWeight:"bolder"}}>*</span></label>
                      <div className="dropdownContentPaymentPlan">
                    <div className="p-field-radiobutton">
                    <RadioButton inputId="monthly" name="monthly" value="1" onChange={(e) => setSelectPlan(e.value)} checked={selectPlan === '1'} />
                    <label htmlFor="monthly">Monthly</label>
                     </div>
                     <div className="p-field-radiobutton">
                    <RadioButton inputId="annually" name="annually" value="3" onChange={(e) => setSelectPlan(e.value)} checked={selectPlan === '3'} />
                    <label htmlFor="annually">Annually</label>
                     </div>
                      </div>
                      </div>
          
                      </div>
                      <div className="spright">
                      {selectedSubscriptionType !== null && selectedSubscriptionType.name !== "All" ?
                      <>
                      {/* <div>Unlock bigger savings with a region subscription — More MSAs, lower cost!</div> */}
                      {selectedSubscriptionType !== null && selectedSubscriptionType.name === "Region" && selectedMsas.length  > 1 ?
                      <div className="subscriptionAlert">
                       <label className="subalertLabel">Unlock bigger savings with a full subscription — More regions, lower cost!</label>
                        </div>
                      : selectedSubscriptionType !== null && selectedSubscriptionType.name === "MSA" && selectedMsas.length  > 1 ?
                      <div className="subscriptionAlert">
                       <label className="subalertLabel">Unlock bigger savings with a region subscription — More MSAs, lower cost!</label>
                        </div>
                      :null}
                      
                     <div className='msaSelectionMainContainer'>
        <div className='subselectionContainer'>
      <div className='subchipContainer'>
      <div className="sub-msa-selector">
        {selectedMsas.length > 0 ?
      <h3>{`My Selection(s)`}</h3>
        :
        <h3>{`No Selection`}</h3>
        }
      <div className="chips-container">
        <Chips
        value={selectedMsas} 
        onChange={(e) => setSelectedMsas(e.value)} 
        itemTemplate={(item) => (<span className="p-chips-token">{item.name}</span>)}
        removeIcon="pi pi-minus"
        onRemove={(e) => handleChipRemove(e.value)}
        className="sub-custom-chips chipspayment"
        allowDuplicate={false}
    />
    </div>
  </div>
    </div>
        <div className="sub-msa-selector">
            <h3>{selectedSubscriptionType.id === 1 ? "Select MSA" : "Select Region"}</h3>
            <InputText value={filter} onChange={(e) => setFilter(e.target.value)} 
             placeholder={selectedSubscriptionType.id === 1 ? "Search MSA" : "Search Region"} className="p-inputtext" />
            <ListBox
                value={null}
                options={filteredMsas}
                onChange={(e) => handleSelectionChange(e.value)}
                optionLabel="name"
                listStyle={{ maxHeight: '200px' ,minHeight:"200px"}}
                itemTemplate={(option) => (
                    <div className="p-d-flex p-jc-between">
                        <span>{option.name}</span>
                        {option.selected ? (
                            <i className="pi pi-minus" onClick={() => handleSelectionChange(option)}></i>
                        ) : (
                            selectedMsas.length < filteredMsas.length && <i className="pi pi-plus" onClick={() => handleSelectionChange(option)}></i>
                        )}
                    </div>
                )}
            />
        </div>
        </div>
        </div>
      </> : null
        // <img className="us-national-map" src="/images/payment/National Map.png" alt="United States" />
      }
                    </div>
                  </div>
                  <div className="dropdownPropertyBasicPaymentPrice">
                    <div className="price-container">
                      {selectedSubscriptionType !== null && selectedMsas.length > 0 || selectedSubscriptionType !== null && selectedSubscriptionType.name === "All" || selectedSubscriptionType === null && subscriptionFor === "All" ? 
                     <>
                     {/* <div className="sub-total">Total:</div> */}
                  <h1 class="sub-card-pricing">
    <span class="sub-card-dollar-sign">$</span>
    <span class="sub-card-number">{subscriptionFor === "All"  || selectedSubscriptionType.name === "All" ? priceToDisplay :  priceToDisplay*selectedMsas.length }</span>
    <span class={selectPlan === "1" ? "sub-card-per-month" : "sub-card-per-year"}>{selectPlan === "1" ? "/month" : "/year"}</span>
  </h1>
  </>
: null}
                    </div>
                  {firstName !== "" && lastName !== "" && email !== "" && !emailerror && !emailspaceerror ?
                  <>
                   {selectedSubscriptionType !== null && selectedMsas.length > 0 || ((selectedSubscriptionType.name === "All" ||selectedSubscriptionType === null && subscriptionFor === "All")) ?
                    <div className="subproceeddiv">
                    <button onClick={handleProceedSubscription} className="sub-proceedbtn">Proceed<i className="pi pi-arrow-right sub-parrow"></i></button>
                    </div>
                    : null}
                  </>
                   :null}
                    </div>
     
                  </div>
                  :
                  <div className="paymentLoaderDiv">
                    <Loader />
                  </div> 
}

       </div>
        </div>
        : 
        <PaymentForm firstName={firstName} lastName={lastName} email={email} subFor={finalSubscriptionFor} selectPlan={finalPlan}
        priceToDisplay={finalAmt}  selectedData={finalSelectedData} msaRegions={msaRegions} />
        }
        </>
}
</>
    );
}

export default AddSubUserForm;